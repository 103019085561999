import { useEffect, useState } from 'react';
import clsx from 'clsx';
import './TheDropdown.scss';
import PropTypes from 'prop-types';

const TheDropdown = ({
  title,
  options,
  setValue,
  initialOption,
  className,
  setFormErrors,
  btnRef,
  disabled = false,
}) => {
  const [isDropdownOpen, setIsDropDownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(initialOption || null);

  const isClock = () => {
    return title === 'Время:' && selectedOption;
  };

  const dropDownHandler = () => {
    setIsDropDownOpen(!isDropdownOpen);
  };

  const onBlurHandler = (e) => {
    if (e.target.classList.contains('dropdown--open')) {
      setIsDropDownOpen(false);
    }
  };

  const onOptionSelect = (option) => {
    if (option.value) {
      setFormErrors && setFormErrors({});
      setValue({ ...option });
      setSelectedOption(option);
      dropDownHandler();
    }
  };

  useEffect(() => {
    setSelectedOption(initialOption);
    setValue(initialOption);
  }, [initialOption]);

  return (
    <div
      className={clsx('dropdown', className, {
        'dropdown--open': isDropdownOpen,
      })}
      tabIndex={0}
      onBlur={onBlurHandler}
    >
      <div className="dropdown__label" name="current">
        {title}
      </div>
      <div className="dropdown__filter">
        {options.length > 0 && (
          <>
            <div
              className={clsx('dropdown__current-item', {
                'dropdown__current-item--with-clock': isClock(),
              })}
              onClick={() => {
                !disabled && dropDownHandler();
              }}
              name="current"
            >
              {isClock() && (
                <div className="dropdown__time-icon">
                  <span className="icon-clock" />
                </div>
              )}
              {selectedOption?.value && (
                <span className="dropdown__current-item__value" name="current">
                  {selectedOption?.value}
                </span>
              )}
              {!selectedOption && <span className="" />}
              <div className="dropdown__arrow">
                <span className="icon-arrow_dropdown" />
              </div>
            </div>
            <div className="dropdown__list-options" name="current">
              <div className="dropdown__list-options-inner">
                {options.map((option, idx) => (
                  <div
                    className="dropdown__option"
                    onClick={() => {
                      onOptionSelect(option);
                      if (btnRef) {
                        btnRef.scrollIntoView({ behavior: 'smooth' });
                      }
                    }}
                    key={idx}
                  >
                    {option.value}
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

TheDropdown.propTypes = {
  title: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  setValue: PropTypes.func.isRequired,
  initialOption: PropTypes.oneOfType([
    PropTypes.shape({
      value: PropTypes.string,
    }),
    PropTypes.string,
  ]),
  className: PropTypes.string,
  setFormErrors: PropTypes.func,
  btnRef: PropTypes.shape({
    scrollIntoView: PropTypes.func,
  }),
  disabled: PropTypes.bool,
};

export default TheDropdown;
