import { useEffect } from 'react';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import './Sidebar.scss';
import SparseDivider from './SparseDivider';
import * as navIcons from '../../assets/icons/sidebar';
import SvgIcon from '@material-ui/core/SvgIcon';
import * as actionCreators from '../../store/actions';
import LoopIcon from '@material-ui/icons/Loop';
import { MODAL_TYPES } from '../../util/constants';

const defaultNavItems = {
  checkLocation: {
    id: -1,
    name: 'Проверить адрес доставки',
    slug: 'проверка-доставки',
    icon: navIcons.checkLocationIcon,
    viewBox: '0 0 21 20',
  },
  delivery: {
    id: -2,
    name: 'Условия доставки',
    slug: 'условия-доставки',
    icon: navIcons.deliveryIcon,
    viewBox: '0 0 21 20',
  },
  contactUs: {
    id: -3,
    name: 'Контакты',
    slug: 'контакты',
    icon: navIcons.contactUsIcon,
    viewBox: '0 0 512 512',
  },
  menu: {
    id: -4,
    name: 'Меню',
    slug: '',
    fontIcon: 'e92a',
  },
  logIn: {
    id: -5,
    name: 'Вход',
    fontIcon: 'e92c',
  },
  logOut: {
    id: -6,
    name: 'Выход',
    fontIcon: 'e92d',
  },
  cart: {
    id: -7,
    name: 'Корзина',
    slug: 'корзина',
    fontIcon: 'e907',
  },
  orders: {
    id: -8,
    name: 'Заказы',
    slug: 'заказы',
    fontIcon: 'e929',
  },
  profile: {
    id: -9,
    name: 'Профиль',
    slug: 'профиль',
    fontIcon: 'e92b',
  },
  offers: {
    id: -10,
    name: 'Акции',
    slug: 'акции',
    fontIcon: 'e948',
  },
};

function getItemDisplay(navItem, idx) {
  if (!navItem) {
    return <SparseDivider key={idx} />;
  }

  const { fontIcon, id, icon, viewBox } = navItem;

  if (fontIcon) {
    return (
      <span className="category-font-icon">
        <span className={`icon-${fontIcon}`} />
      </span>
    );
  }

  if (id > 0) {
    const style = icon ? { background: `url('${icon}') center no-repeat` } : {};
    return <div style={style} className="category-icon" />;
  }

  return (
    <SvgIcon component={icon} className="category-icon" viewBox={viewBox} />
  );
}

const useClasses = makeStyles((theme) => {
  const getBasicStyle = (close) => ({
    overflowX: 'hidden',
    maxHeight: '100vh',
    overflowY: 'auto',
    width: theme.sidebarWidth[close ? 'closed' : 'open'],
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration:
        theme.transitions.duration[close ? 'leavingScreen' : 'enteringScreen'],
    }),
  });

  const closeStyle = getBasicStyle(true);
  const openStyle = getBasicStyle(false);

  const getPaperContentStyle = (close, screenIsLarge) => {
    const style = {
      ...(close ? closeStyle : openStyle),
    };
    if (screenIsLarge) {
      style.width += 18;
    }
    style.padding = screenIsLarge ? '105px 0 0' : '100px 0 70px';
    return style;
  };

  return {
    drawerOpen: openStyle,
    drawerClose: closeStyle,
    paperContentOpenLg: getPaperContentStyle(false, true),
    paperContentOpenSm: getPaperContentStyle(false, false),
    paperContentCloseLg: getPaperContentStyle(true, true),
    paperContentCloseSm: getPaperContentStyle(true, false),
  };
});

function Sidebar({ isOpen, openSidebar, closeSidebar }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const screenIsLarge = useSelector((state) => state.view.screenIsLarge);
  const isSidebarAlwaysOpened = useSelector(
    (state) => state.view.isSidebarAlwaysOpened,
  );
  const menuCategories = useSelector((state) => state.menus.menuCategories);
  const isAuthorized = useSelector((state) => state.currentUser.isAuthorized);
  const companyInfo = useSelector((state) => state.companyInfo.info);

  useEffect(() => {
    if (isSidebarAlwaysOpened) {
      openSidebar();
    }
  }, [isSidebarAlwaysOpened, openSidebar]);

  const smallScreenNavItems = !isAuthorized
    ? [
        null,
        defaultNavItems.menu,
        defaultNavItems.logIn,
        null,
        defaultNavItems.delivery,
        defaultNavItems.contactUs,
        defaultNavItems.offers,
      ]
    : [
        null,
        defaultNavItems.menu,
        defaultNavItems.cart,
        defaultNavItems.orders,
        defaultNavItems.profile,
        defaultNavItems.logOut,
        null,
        defaultNavItems.delivery,
        defaultNavItems.contactUs,
        defaultNavItems.offers,
      ];

  const classes = useClasses();

  function navigate(navItem) {
    const to = navItem.id > 0 ? `${navItem.id}-${navItem.slug}` : navItem.slug;
    history.push(`/${to}`);
  }

  const sidebarProps = {
    variant: 'temporary',
    className: '',
  };

  if (screenIsLarge) {
    sidebarProps.variant = 'permanent';

    sidebarProps.className = clsx({
      [classes.drawerOpen]: isOpen,
      [classes.drawerClose]: !isOpen,
    });
    sidebarProps.classes = {
      paper: clsx({
        [classes.drawerOpen]: isOpen,
        [classes.drawerClose]: !isOpen,
      }),
    };
  }

  sidebarProps.className += ' BasicSidebar';

  const navItems = screenIsLarge
    ? [
        defaultNavItems.checkLocation,
        defaultNavItems.offers,
        null,
        ...menuCategories,
        null,
        defaultNavItems.delivery,
        defaultNavItems.contactUs,
      ]
    : smallScreenNavItems;

  if (!menuCategories.length) {
    return null;
  }

  return (
    <SwipeableDrawer
      onClose={closeSidebar}
      onOpen={openSidebar}
      open={isOpen}
      ModalProps={{
        BackdropProps: {
          invisible: screenIsLarge,
        },
      }}
      {...sidebarProps}
    >
      <div
        className={clsx({
          [classes.paperContentCloseSm]: !isOpen && !screenIsLarge,
          [classes.paperContentCloseLg]: !isOpen && screenIsLarge,
          [classes.paperContentOpenSm]: isOpen && !screenIsLarge,
          [classes.paperContentOpenLg]: isOpen && screenIsLarge,
        })}
      >
        <List>
          {companyInfo?.visible_root_domain_link && (
            <ListItem
              button
              onClick={() => !isSidebarAlwaysOpened && closeSidebar()}
              disableRipple
              title="Перейти на старую версию сайта"
              className="sidebar__link__wrapper"
            >
              <a
                className="sidebar__link"
                href="https://xn--2-ctb.xn--80aaa3clh0b.xn--p1ai/"
                rel="noopener noreferrer"
              >
                <LoopIcon className="category-icon" viewBox="0 0 20 20" />
                <ListItemText primary="Перейти на старую версию сайта" />
              </a>
            </ListItem>
          )}
          {navItems.map((item, idx) =>
            item ? (
              <ListItem
                key={item.id}
                button
                onClick={() => {
                  !isSidebarAlwaysOpened && closeSidebar();
                  item.slug != null
                    ? navigate(item)
                    : dispatch(
                        actionCreators.setModalContent(
                          item.name !== 'Выход'
                            ? { modalType: MODAL_TYPES.LOG_IN }
                            : { modalType: MODAL_TYPES.LOG_OUT },
                        ),
                      );
                }}
                disableRipple
                title={item.name}
              >
                {getItemDisplay(item, idx)}
                <ListItemText primary={item.name} />
              </ListItem>
            ) : (
              <SparseDivider key={idx - 3 * navItems.length} />
            ),
          )}
        </List>
      </div>
    </SwipeableDrawer>
  );
}

Sidebar.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  openSidebar: PropTypes.func.isRequired,
  closeSidebar: PropTypes.func.isRequired,
};

export default Sidebar;
